<template>
  <h4 :class="$style['heading-4']">
    <slot />
  </h4>
</template>

<style lang="scss" module>
.heading-4 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 124%;
  letter-spacing: -3%;

  @media screen and (max-width: $tablet) {
    font-size: 1.25rem;
    line-height: 120%;
    letter-spacing: -1%;
  }

  @media screen and (max-width: $mobile) {
    font-size: 1rem;
    line-height: 110%;
    letter-spacing: -2%;
  }
}
</style>
